import React from "react";

// Images
import ArrowIcon from "../../assets/icon_right_arrow.svg";

// Utils
import { handleOverrideClassName, handleOverrideConfig, handleRedirect } from "../../utils/CommonFunctions";

// Services
import i18n from "../../i18n";

function PI2({
  item,
  theme,
  history,
  pageName
}) {
  const arrowIconImg = (item.icon_image && item.icon_image.path) || ArrowIcon;
  const arrowIconImgAlt = item.icon_image && item.icon_image.alt_text;

  return (
    <button
      className={`pi2 ${handleOverrideClassName(item.classname)}`}
      style={
                ({ boxShadow: handleOverrideConfig(theme.dropShadow, item.dropShadow) && "0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)" })
            }
      onClick={() => handleRedirect(item.button_action, history, item.title, pageName)}

    >
      <div
        className="content-section"
        style={{
          cursor: `${handleOverrideConfig(theme.showIcon, item.showIcon) ? "pointer" : "unset"}`,
          borderRadius: `${!handleOverrideConfig(theme.showImage, item.showImage) && "8px"}`
        }}
        // onClick={() => handleRedirect(item.button_action, history, item.title, pageName)}
      >
        {/* Logo Image */}

        {
          (handleOverrideConfig(theme.showLogoImage, item.showLogoImage) && item.logo_image)
          && <img className={`logo-img-icon ${handleOverrideClassName(item.logoImageClassname)}`} src={item.logo_image.path} alt={item.logo_image.alt_text} />
        }

        {/* Title text */}

        {
                    item.title
                    && <h3 className={`header-text ${handleOverrideClassName(item.headerTextClassname)}`}>{i18n.t(item.title)}</h3>
                }

        {/* Description */}

        {
                    item.description
                    && <p className={`bodyText ${handleOverrideClassName(item.bodyTextClassname)}`}>{i18n.t(item.description)}</p>
                }

        {/* Arrow icon */}

        {
                    handleOverrideConfig(theme.showIcon, item.showIcon)
                    && <img className={`icon-img ${handleOverrideClassName(item.iconClassname)}`} src={arrowIconImg} alt={arrowIconImgAlt} />
                }
      </div>

      {
                handleOverrideConfig(theme.showImage, item.showImage)
                && (
                <div
                  className={`img-card ${handleOverrideClassName(item.imgClassname)}`}
                  style={
                        ({ background: (item.image && item.image.path) && `url(${item.image.path})` })
                    }
                />
                )
            }
    </button>
  );
}

export default PI2;
