import React from "react";

// Components
import RowItems from "./RowItem";

// Utils
import { handleOverrideClassName } from "../../utils/CommonFunctions";

const RegularRow = ({ pageContent, theme, pageName }) => {
    return (
        <>
            {
                pageContent.items && pageContent.items.length
                ?
                <div className={`row-container ${handleOverrideClassName(pageContent.rowContainerClassname)}`}>
                    {
                        pageContent.items.map((item, i) => (
                            <RowItems 
                                key={i}
                                rowItem={item}
                                theme={theme}
                                pageName={pageName}
                            />
                        ))
                    }
                </div>
                :
                null
            }
        </>
    );
}

export default RegularRow;
