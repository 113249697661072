// Utils
import { handleAnalyticsTrack } from "./Analytics";
import { postMessage } from "../services/NativePostMessage";

// Services
import i18n from "../i18n";

export const handleOverrideClassName = (pageClassname) => {
  let tempPageClassname = "";

  if (pageClassname) {
    tempPageClassname = pageClassname;
  }

  return `${tempPageClassname}`;
};

export const handleOverrideConfig = (themeConfig, pageConfig) => {
  let tempConfigValue;

  if (typeof themeConfig !== "undefined") {
    tempConfigValue = themeConfig;
  }

  if (typeof pageConfig !== "undefined") {
    tempConfigValue = pageConfig;
  }

  return tempConfigValue;
};

export const handleRedirect = (button_action, history, buttonTitle, pageName) => {
  const tempPagename = pageName || "homepage";

  if (button_action) {
    if (button_action.type === "URL") {
      return window.location.assign(button_action.url);
    }

    if (button_action.type === "IN_APP") {
      history(`/${button_action.destinationPageId}`);
    }

    if (button_action.type === "OPEN_WALLET") {
      if (window.webkit) {
        window.webkit.messageHandlers.ToApp.postMessage("WebPageCallbackOpenWallet");
      }

      if (window.Android) {
        window.Android.openWallet();
      }
    }

    if (button_action.type === "CONTACT") {
      const contactInfo = {
        supportEmail: button_action.supportEmail,
        supportEmailSubject: button_action.supportEmailSubject,
        supportPhoneNumber: button_action.supportPhoneNumber
      };

      if (window.webkit) {
        postMessage("showContactSheet", contactInfo);
      }

      if (window.Android) {
        // Android doesn't accept an object so stringifying
        postMessage("showContactSheet", contactInfo.supportEmail, contactInfo.supportEmailSubject, contactInfo.supportPhoneNumber);
      }
    }

    if (button_action.type === "IN_APP_BROWSER") {
      if (window.webkit) {
        window.webkit.messageHandlers.ToApp.postMessage({
          type: "IN_APP_BROWSER",
          url: button_action.url
        });
      }

      if (window.Android) {
        window.Android.openWebLink("IN_APP_BROWSER", button_action.url);
      }
    }

    // Analytics
    // IOS
    if (window.webkit) {
      window.webkit.messageHandlers.ToApp.postMessage({
        type: "ANALYTICS",
        actions: "onClick_event",
        page: tempPagename,
        reason: `Clicked - ${i18n.t(buttonTitle)} button`,
        button_action
      });
    } else if (window.Android) {
      // Android
      window.Android.registerAnalyticsEvent("onClick_event", tempPagename, `Clicked - ${i18n.t(buttonTitle)} button`);
    } else {
      // Web
      handleAnalyticsTrack(tempPagename, "onClick_event", `Clicked - ${i18n.t(buttonTitle)} button`);
    }
  }
};

export const handleSliceContent = (numberOfItemsToShow, items) => {
  let itemReturn = items;
  if (numberOfItemsToShow && items?.length > numberOfItemsToShow) {
    itemReturn = items.splice(0, numberOfItemsToShow);
  }

  return itemReturn;
};
