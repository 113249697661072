import { useTranslation } from "react-i18next";

interface DistanceBoxProps {
    value: number | undefined;
    isStepSliderActive?: boolean;
  }

const DistanceBox = ({
    value,
    isStepSliderActive = false,
  }: DistanceBoxProps) => {
    const [t] = useTranslation("translations");
  
    const handleDefaultDistanceStyles = isStepSliderActive
      ? ""
      : "textfield__input--grey";
  
    const displayValue =
      `${value} km` || t("LocationSelection.AddLocation.distance_placeholder");
  
    return (
      <div className="textfield">
        <p className="textfield__label">
          {t("LocationSelection.AddLocation.distance_label")}
        </p>
        <p
          className={`textfield__input textfield__input--small ${handleDefaultDistanceStyles}`}
        >
          {displayValue}
        </p>
      </div>
    );
  }

  export default DistanceBox;