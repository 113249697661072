type NativeBridgeMessageTypes = "WebPageCallbackOpenWallet" | "setBackButtonVisible" | "setBottomBarVisible" | "setTopBarVisible"

let postMessage: (type: NativeBridgeMessageTypes, value: any) => void;

const postMessageCache = {
  __cache: {},
  isCached(type, value) {
    if (this.__cache[type] === value) {
      return true;
    } else {
      this.__cache[type] = value;
      return false;
    }
  },
};

const postMessageAndroid = (type, ...args) => {
  if (postMessageCache.isCached(type, args)) return;

  if (window.Android[type] != null) {
    window.Android[type](...args);
  }
};

const postMessageIos = (type, value) => {
  if (postMessageCache.isCached(type, value)) return;

  window.webkit?.messageHandlers?.ToApp?.postMessage({
    type: type,
    value: value,
  });
};

const initializeNativePostMessageService = () => {
  if (window.webkit) {
    postMessage = postMessageIos;
  } else if (window.Android) {
    postMessage = postMessageAndroid;
  } else {
    postMessage = () => {
      // This is intentional, non native devices do not need this
    };
  }
};

export { postMessage, initializeNativePostMessageService };