import React from "react";

// Services
import i18n from "../../i18n";

// Images
import ArrowIcon from "../../assets/icon_right_arrow.svg";

const Button = ({ buttonType, buttonClassname, buttonText, handleRedirect, showButtonIcon, buttonIconImage, buttonIconPosition, buttonIconClassname }) => {
    const handleClassName = () => {
        let tempClassname = "text-button";

        if (buttonType === "TEXT") {
            tempClassname = "text-button";
        }

        if (buttonType === "OUTLINED") {
            tempClassname = "outlined-button";
        }

        if (buttonType === "FILL") {
            tempClassname = "fill-button";
        }

        return tempClassname;
    }

    const arrowIconImg = (buttonIconImage && buttonIconImage.path) || ArrowIcon;
    const arrowIconImgAlt = buttonIconImage && buttonIconImage.alt_text;
    
    return (
        <button 
            className={`action-button ${handleClassName()} ${buttonClassname}`}
            onClick={handleRedirect}
            style={
                Object.assign({
                    flexDirection: buttonIconPosition === "LEFT" ? "row-reverse" : "row",
                }, {})
            }
        >
            { i18n.t(buttonText) }


            {/* Arrow Icon */}
                    
            {
                showButtonIcon
                &&
                <img 
                    className={`icon-img ${buttonIconClassname}`} 
                    src={arrowIconImg} 
                    alt={arrowIconImgAlt} 
                    style={
                        Object.assign({
                            marginLeft: buttonIconPosition === "LEFT" && 0,
                            marginRight: buttonIconPosition === "LEFT" && 8,
                        }, {})
                    }
                />
            }
        </button>
    );
}

export default Button;
