import React from "react";
import SwipeDelete from "../../../Reusable/SwipeDelete";

interface ResultItemProps {
  secondary: string;
  main: string;
  image: string;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  index: number;
  onDelete: (index: number) => void;
}

const OhtCard = ({
  main,
  secondary,
  image,
  onClick,
  index,
  onDelete,
}: ResultItemProps) => {
  return (
    <SwipeDelete index={index} onDelete={onDelete} onClick={onClick}>
      <div className="ls__locationCard" onClick={onClick}>
        <div className="ls__locationCard_left--oht">
          <img
            src="/assets/ohts/scoht_left.png"
            alt={main}
            className="ls__locationCard_image"
          />
        </div>
        <div className="ls__locationCard_right">
          <p className="ls__locationCard_cityprovince">{main}</p>
          <p className="ls__locationCard_postalcode">{secondary}</p>
        </div>
      </div>
    </SwipeDelete>
  );
};

export default OhtCard;
